import { actions } from './actions'

const initialState = {
  data: {}, // nothing received from backend on massStore
  loading: false,
  success: null,
  message: null,
}

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...state,
        loading: true,
        success: null,
        message: null,
      }
    case actions.SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload.data,
      }
    case actions.RESET:
    case actions.ERROR:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default Reducer
