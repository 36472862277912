import { isEmpty, find } from 'lodash'
import { distance, point } from '@turf/turf'
import getCarAngleUSA from 'utils/getCarAngle'
import { actions } from './actions'

const initialState = {
  data: [],
  loading: false,
}

const addAngle = (prevState, newState) => {
  let result = []

  if (isEmpty(prevState)) {
    result = newState.map((driver) => {
      return { ...driver, angle: 0 }
    })
  } else {
    result = newState.map((driver) => {
      const prevDriver = find(prevState, { license: driver.license })

      if (!prevDriver) {
        return { ...driver, angle: 0 }
      }

      const from = point([prevDriver.lng, prevDriver.lat])
      const to = point([driver.lng, driver.lat])
      const dist = distance(from, to)

      if (dist * 1000 > 5) {
        return {
          ...driver,
          angle: getCarAngleUSA(prevDriver.lat, prevDriver.lng, driver.lat, driver.lng),
        }
      }

      return {
        ...driver,
        angle: prevDriver.angle,
      }
    })
  }

  return result
}

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.SUCCESS:
      return {
        ...state,
        loading: false,
        data: addAngle(state.data, [...action.payload.data.data]),
      }
    case actions.RESET:
    case actions.ERROR:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default Reducer
