import { notification } from 'antd'
import { history } from 'index'
import { all, takeEvery, put } from 'redux-saga/effects'
import request from 'redux/network/actions'
import { actions } from './actions'
import { dispatchRideFlexible } from '../api'

function* LOAD({ payload }) {
  yield put(request(dispatchRideFlexible(payload), actions.LOAD))
}

function* SUCCESS(payload) {
  if (payload.payload.data.success) {
    yield notification.success({
      message: 'Ride dispatched successfully!',
    })

    yield history.push('/flex')
  } else {
    const msg = payload.payload.data.message || 'Something went wrong!'
    yield notification.error({
      message: msg,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, LOAD), takeEvery(actions.SUCCESS, SUCCESS)])
}
