import { networkActions } from 'redux/network/actions'

const actions = {
  ...networkActions('coordinator/delete'),
}

const destroy = (id) => {
  return {
    type: actions.LOAD,
    payload: {
      id,
    },
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, destroy, reset }
