import axios from 'axios'
import flexiblePolyline from 'utils/flexiblePolyline'
import { isEmpty } from 'lodash'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import request from 'redux/network/actions'
import { actions } from './actions'
import ridesList from './api'

const { decode } = flexiblePolyline

const url = 'https://router.hereapi.com/v8/routes?apiKey=v9uFC8pPf68qaWe_WulrsglbeoHbFkkLQUlRffpbblk&transportMode=car&units=imperial&return=summary'

function* LOAD(payload) {
  yield put(request(ridesList(payload.payload), actions.LOAD))
}

function* SUCCESS(payload) {
  const results = yield all(payload.payload.data.data.rides.map((el) => call(addSummary, el)))

  const toSend = {
    ...payload.payload,
  }

  toSend.data.data.rides = results

  yield put({
    type: `${actions.EXPAND}`,
    payload: toSend,
  })
}

function addSummary(ride) {
  if (ride.data.ride_status !== 'Onride') {
    return ride
  }

  const decodedRoute = decode(ride.route.route).polyline

  if (isEmpty(decodedRoute) || decodedRoute.length < 2) {
    return ride
  }

  let newUrl = ''
  newUrl += url

  decodedRoute.forEach((el, index, arr) => {
    if (index === 0) {
      newUrl += `&origin=${el[0]},${el[1]}`
    } else if (index === arr.length - 1) {
      newUrl += `&destination=${el[0]},${el[1]}`
    } else {
      newUrl += `&via=${el[0]},${el[1]}`
    }
  })

  return axios
    .get(newUrl)
    .then((data) => {
      let time = 0

      data.data.routes[0].sections.forEach((el) => {
        time += el.summary.duration
      })

      const toSend = {
        ...ride,
      }

      toSend.data.extra_status = `Dropoff in ${parseInt(time / 60, 10)}min`

      return toSend
    })
    .catch((reason) => {
      console.log('Caught error ridesList addSummary', reason, ride)
      return ride
    })
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD), takeLatest(actions.SUCCESS, SUCCESS)])
}
