import { all, takeEvery, put } from 'redux-saga/effects'
import { notification } from 'antd'
import store from 'store'
import request from 'redux/network/actions'
import { history, store as reduxStore } from 'index'
import { userLogin, userProfile, userResetPassword } from './api'
import { actions, profile } from './actions'

function* LOGIN(payload) {
  const { subdomain } = yield reduxStore.getState().settings

  yield put(request(userLogin({ ...payload.payload, subdomain }), actions.LOAD))
}

function* LOGOUT() {
  yield store.remove('app.token')
  yield store.remove('app.baId')
  yield put({
    type: actions.RESET,
  })
}

function* SUCCESS(payload) {
  // check if loggedin user has manager role in BA
  const { company_role } = payload.payload.data.user;
  if (company_role !== 'MANAGER') {
    yield store.remove('app.token')
    yield store.remove('app.baId')
    yield put({
      type: actions.RESET,
    })
  } else {
    const {
      payload: {
        data: {
          access_token,
          user: {
            company_name: {
              id
            }
          }
        },
      },
    } = payload
    // Save token to localStorage
    yield store.set('app.token', access_token)
    yield store.set('app.baId', id)

    yield notification.success({
      message: 'Login !',
      description: 'You have successfully logged in!',
    })
  }

  yield history.push('/')
}

function* PROFILE(payload) {
  yield put(request(userProfile(payload.payload.token), actions.PROFILE))
}

function* PROFILE_SUCCESS(payload) {
  const { company_role } = payload.payload.data.user;

  // check if loggedin user has manager role in BA
  if (company_role !== 'MANAGER') {
    yield store.remove('app.token')
    yield store.remove('app.baId')
    yield put({
      type: actions.RESET,
    })
  }
  yield notification.success({
    message: 'Login !',
    description: 'You have successfully logged in!',
  })
}

function* CheckToken() {
  const token = yield store.get('app.token')

  if (token) {
    yield put(profile(token))
  }
}

function* ResetPassword(payload) {
  yield put(request(userResetPassword(payload.payload), actions.RESETPASSWORD))
}

function* ResetPasswordSuccess(payload) {
  yield notification.success({
    message: 'Reset password sent !',
    description: payload.payload.data.message,
  })

  yield history.push('/auth/login')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.SUCCESS, SUCCESS),
    takeEvery(actions.PROFILE, PROFILE),
    takeEvery(actions.PROFILE_SUCCESS, PROFILE_SUCCESS),
    CheckToken(),
    takeEvery(actions.RESETPASSWORD, ResetPassword),
    takeEvery(actions.RESETPASSWORD_SUCCESS, ResetPasswordSuccess),
  ])
}
