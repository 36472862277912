import { postJson } from 'utils/net'
import APP_CONST from 'utils/constants'

const { url, GetToken } = APP_CONST

const apiUrl = `${url}/availableDrivers`

const availableDrivers = () => postJson(apiUrl, null, GetToken())

export default availableDrivers
