import { all, put, takeLatest } from 'redux-saga/effects'
import request from 'redux/network/actions'
import { actions } from './actions'
import API from '../api'
import { actions as deleteActions } from '../delete/actions'

const { locations } = API

function* LOAD() {
  yield put(request(locations(), actions.LOAD))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD), takeLatest(deleteActions.SUCCESS, LOAD)])
}
