import { networkActions } from 'redux/network/actions'

const actions = {
  ...networkActions('location/create'),
}

const create = (params) => {
  return {
    type: actions.LOAD,
    payload: {
      ...params,
    },
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, create, reset }
