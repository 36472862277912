import store from 'store'
import actions from './actions'

const STORED_SETTINGS = (storedSettings) => {
  const settings = {}
  Object.keys(storedSettings).forEach((key) => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

const getSubdomainSettings = () => {
  const conciergeSettings = {
    subdomain: '',
    layoutMenu: 'simply',
    menuLayoutType: 'top',
  }

  const businessSettings = {
    subdomain: 'business',
    layoutMenu: 'classic',
    menuLayoutType: 'left',
  }

  const available = ['business', 'health', 'concierge']
  const subdomain = window.location.hostname.split('.')[0]

  if (subdomain === available[0]) {
    return businessSettings
  }

  conciergeSettings.subdomain = subdomain
  return conciergeSettings
}

const initialState = {
  ...STORED_SETTINGS({
    // Read docs for available values: https://docs.visualbuilder.cloud
    // VB:REPLACE-START:SETTINGS
    authProvider: 'jwt',
    logo: 'Waave',
    version: 'Concierge',
    theme: 'default',
    locale: 'en-US',
    isSidebarOpen: false,
    isSupportChatOpen: false,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    isPreselectedOpen: false,
    preselectedVariant: 'waves',
    routerAnimation: 'none',
    authPagesColor: 'image',
    isAuthTopbar: true,
    isMenuUnfixed: false,
    isMenuShadow: false,
    isTopbarFixed: false,
    isTopbarSeparated: true,
    isGrayTopbar: false,
    isContentMaxWidth: false,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isCardShadow: false,
    isSquaredBorders: false,
    isBorderless: false,
    layoutTopbar: 'none',
    layoutBreadcrumbs: 'noBreadcrumbs',
    layoutFooter: 'noFooter',
    flyoutMenuType: 'flyout',
    flyoutMenuColor: 'blue',
    menuColor: 'dark',
    primaryColor: '#18AB47',
    leftMenuWidth: 190,
    // VB:REPLACE-END:SETTINGS

    // Waave business & concierge
    // layoutMenu: 'simply',
    // menuLayoutType: 'top',
    ...getSubdomainSettings(),
  }),
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
