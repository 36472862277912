import { actions } from './actions'

const initialState = {
  authorized: false,
  loading: false,

  // API response
  admin_id: '',
  admin_name: '',
  first_name: '',
  email: '',
  admin_type: '',
  api_token: null,
  company_name: null,
  location: null,
}

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST:
    case actions.PROFILE:
      return {
        ...state,
        loading: true,
      }
    case actions.SUCCESS:
    case actions.PROFILE_SUCCESS:
      return {
        ...state,
        authorized: true,
        loading: false,
        ...action.payload.data.user,
      }
    case actions.RESET:
    case actions.ERROR:
    case actions.PROFILE_ERROR:
    case actions.PROFILE_RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default Reducer
