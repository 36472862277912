import { notification } from 'antd'
import { all, takeEvery, put } from 'redux-saga/effects'
import request from 'redux/network/actions'
import { actions } from './actions'
import updateRide from './api'

function* LOAD(payload) {
  yield put(request(updateRide(payload.payload), actions.LOAD))
}

function* SUCCESS(payload) {
  if (payload.payload.data.success) {
    yield notification.success({
      message: 'Ride updated successfully!',
    })
  } else {
    yield notification.error({
      message: payload.payload.data.message,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, LOAD), takeEvery(actions.SUCCESS, SUCCESS)])
}
