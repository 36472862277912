import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import network from './network/sagas'
import hereRouter from './hereRouter/sagas'
import calculateFare from './calculateFare/sagas'
import dispatchRide from './dispatchRide/sagas'
import ridesList from './ridesList/sagas'
import cancelRide from './cancelRide/sagas'
import availableDrivers from './availableDrivers/sagas'
import ridesHistory from './ridesHistory/sagas'

import locationsList from './locations/list/sagas'
import locationCreate from './locations/create/sagas'
import locationDelete from './locations/delete/sagas'
import locationUpdate from './locations/update/sagas'

import coordinatorsList from './coordinators/list/sagas'
import coordinatorCreate from './coordinators/create/sagas'
import coordinatorDelete from './coordinators/delete/sagas'
import coordinatorUpdate from './coordinators/update/sagas'
import coordinatorResendInvite from './coordinators/resendInvite/sagas'

import updateRide from './updateRide/sagas'

// import companyAdmins from './companyAdmins/list/sagas'
// import companyAdminCreate from './companyAdmins/create/sagas'
// import companyAdminUpdate from './companyAdmins/update/sagas'
// import companyAdminDelete from './companyAdmins/delete/sagas'
import companyAdminResendInvite from './companyAdmins/resendInvite/sagas'

import companySettings from './companySettings/show/sagas'
import companySettingsUpdate from './companySettings/update/sagas'

import employees from './employees/list/sagas'
import employeeCreate from './employees/create/sagas'
import employeeDelete from './employees/delete/sagas'
import employeeUpdate from './employees/update/sagas'

import companyAdmins from './admins/list/sagas'
import companyAdminDelete from './admins/delete/sagas'
import companyAdminCreate from './admins/create/sagas'
import companyAdminUpdate from './admins/update/sagas'

import companyBilling from './companyBilling/show/sagas'

import dispatchRideFlexible from './flexibleRides/create/sagas'
import flexibleRides from './flexibleRides/list/sagas'

export default function* rootSaga() {
  yield all([
    network(),
    user(),
    menu(),
    settings(),
    hereRouter(),
    calculateFare(),
    dispatchRide(),
    ridesList(),
    cancelRide(),
    availableDrivers(),
    ridesHistory(),

    locationsList(),
    locationCreate(),
    locationDelete(),
    locationUpdate(),

    coordinatorsList(),
    coordinatorCreate(),
    coordinatorDelete(),
    coordinatorUpdate(),
    coordinatorResendInvite(),

    updateRide(),

    companyAdmins(),
    companyAdminCreate(),
    companyAdminUpdate(),
    companyAdminDelete(),
    companyAdminResendInvite(),

    companySettings(),
    companySettingsUpdate(),

    employees(),
    employeeCreate(),
    employeeUpdate(),
    employeeDelete(),

    companyBilling(),

    dispatchRideFlexible(),
    flexibleRides(),
  ])
}
