import { all, put, takeLatest } from 'redux-saga/effects'
import request from 'redux/network/actions'
import { actions } from './actions'
import availableDrivers from './api'

function* LOAD(payload) {
  yield put(request(availableDrivers(), actions.LOAD, payload))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD)])
}
