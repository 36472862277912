import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'

import hereRouter from './hereRouter/reducers'
import calculateFare from './calculateFare/reducers'
import availableDrivers from './availableDrivers/reducers'

import dispatchRide from './dispatchRide/reducers'

import ridesHistory from './ridesHistory/reducers'
import ridesList from './ridesList/reducers'
import updateRide from './updateRide/reducers'
import cancelRide from './cancelRide/reducers'

import locationsList from './locations/list/reducers'
import locationCreate from './locations/create/reducers'
import locationDelete from './locations/delete/reducers'
import locationUpdate from './locations/update/reducers'

import coordinatorsList from './coordinators/list/reducers'
import coordinatorCreate from './coordinators/create/reducers'
import coordinatorDelete from './coordinators/delete/reducers'
import coordinatorUpdate from './coordinators/update/reducers'
import coordinatorResendInvite from './coordinators/resendInvite/reducers'

// import companyAdmins from './companyAdmins/list/reducers'
// import companyAdminCreate from './companyAdmins/create/reducers'
// import companyAdminUpdate from './companyAdmins/update/reducers'
// import companyAdminDelete from './companyAdmins/delete/reducers'
import companyAdminResendInvite from './companyAdmins/resendInvite/reducers'

import companySettings from './companySettings/show/reducers'
import companySettingsUpdate from './companySettings/update/reducers'

import employees from './employees/list/reducers'
import employeeCreate from './employees/create/reducers'
import employeeDelete from './employees/delete/reducers'
import employeeUpdate from './employees/update/reducers'

import companyAdmins from './admins/list/reducers'
import companyAdminDelete from './admins/delete/reducers'
import companyAdminCreate from './admins/create/reducers'
import companyAdminUpdate from './admins/update/reducers'

import companyBilling from './companyBilling/show/reducers'

import dispatchRideFlexible from './flexibleRides/create/reducers'
import flexibleRides from './flexibleRides/list/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    hereRouter,
    calculateFare,
    dispatchRide,
    ridesList,
    cancelRide,
    availableDrivers,
    ridesHistory,

    locationsList,
    locationCreate,
    locationDelete,
    locationUpdate,

    coordinatorsList,
    coordinatorCreate,
    coordinatorDelete,
    coordinatorUpdate,
    coordinatorResendInvite,

    updateRide,

    companyAdmins,
    companyAdminCreate,
    companyAdminUpdate,
    companyAdminDelete,
    companyAdminResendInvite,

    companySettings,
    companySettingsUpdate,

    employees,
    employeeCreate,
    employeeUpdate,
    employeeDelete,

    companyBilling,

    dispatchRideFlexible,
    flexibleRides,
  })
