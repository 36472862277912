import { all, put, takeLatest } from 'redux-saga/effects'
import request from 'redux/network/actions'
import { actions } from './actions'
import API from '../api'

const { locationCreate } = API

function* LOAD({ payload }) {
  yield put(request(locationCreate(payload), actions.LOAD))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD)])
}
