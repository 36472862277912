import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import classNames from 'classnames'
import store from 'store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { find } from 'lodash'
import Icon from '@ant-design/icons'

import WaaveBusiness from 'assets/logo/waave_business_green_white.png'

import { Icon as Iconify } from '@iconify/react'
import myLocation16Filled from '@iconify/icons-fluent/my-location-16-filled'

import UserMenu from './UserMenu'

import style from './style.module.scss'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  user,
  // logo: settings.logo,
  // version: settings.version,
  // subdomain: settings.subdomain,
})

const MenuLeft = ({
  dispatch,
  menuData = [],
  location: { pathname },

  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  // logo,
  // version,
  user: {
    company_name: { name },
    role,
    admin_type,
  },
  // subdomain,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = (keys) => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = (e) => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const generateMenuItems = () => {
    const generateItem = (item) => {
      const { key, title, url, icon, disabled, count, iconComp, iconWrap } = item

      const ChooseIcon = () => {
        if (!icon) {
          // return <span style={{ marginRight: '24px' }} />
          return null
        }

        if (icon !== 'custom') {
          return <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />
        }

        if (iconWrap) {
          return iconComp
        }

        return <Icon component={iconComp} />
      }

      if (item.divider) {
        return <Menu.Divider key={Math.random()} style={{ marginTop: '1rem', marginBottom: '1rem' }} />
      }

      if (item.category) {
        return (
          <Menu.ItemGroup
            key={Math.random()}
            title={
              <>
                {item.url && item.target && (
                  <a href={url} target={item.target} rel="noopener noreferrer">
                    <ChooseIcon />

                    <span className={style.title}>{title}</span>
                    {count && <span className="badge badge-success ml-2">{count}</span>}

                    {/* {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />} */}
                  </a>
                )}

                {item.url && !item.target && (
                  <Link to={url}>
                    <ChooseIcon />
                    <span className="ml-2">{item.title}</span>
                  </Link>
                )}

                {!item.url && (
                  <>
                    <ChooseIcon />
                    <span className="ml-2">{item.title}</span>
                  </>
                )}
              </>
            }
          />
        )
      }

      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                <ChooseIcon />

                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}

                {/* {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />} */}
              </a>
            )}

            {!item.target && (
              <Link to={url}>
                <ChooseIcon />

                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}

                {/* {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />} */}
              </Link>
            )}
          </Menu.Item>
        )
      }

      return (
        <Menu.Item key={key} disabled={disabled}>
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
          {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
        </Menu.Item>
      )
    }

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map((menuItem) => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
          </span>
        )
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      }

  const getUrlByAdminType = () => {
    // admin_type === 'corporate_operator' ? 'https://health.waave.co' : 'https://concierge.waave.co'
    if (['corporate', 'corporate_operator'].includes(admin_type)) {
      return 'https://health.waave.link/'
    }

    return 'https://health.waave.link/'
  }

  return (
    <Layout.Sider
      {...menuSettings}
      collapsible={false}
      collapsed={false}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          // width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          width: leftMenuWidth,
          // height: isMobileView || isMenuUnfixed ? 'calc(100vh - 64px)' : 'calc(100vh - 110px)',
        }}
      >
        <div className={style.logoContainer}>
          <div className={style.logo}>
            {/* <div className={style.name}>{logo}</div> */}

            {/* <img src="/resources/images/waave/logo.svg" alt="logo" /> */}
            {/* <div className={style.descr}>{subdomain}</div> */}
            <img src={WaaveBusiness} alt="logo" style={{ height: '18px' }} />
          </div>
        </div>

        <div className={style.forCompany}>for {name}</div>

        <PerfectScrollbar
          style={{
            display: 'grid',
            gridTemplateRows: 'max-content auto',
          }}
        >
          <Menu onClick={handleClick} selectedKeys={selectedKeys} openKeys={openedKeys} onOpenChange={onOpenChange} mode="inline" className={style.navigation} inlineIndent={20}>
            {generateMenuItems()}
          </Menu>

          <div
            style={{
              alignSelf: 'end',
              marginBottom: '10rem',
              marginLeft: '1rem',
              marginRight: '1rem',
            }}
          >
            <a href={getUrlByAdminType()} target="_blank" rel="noopener noreferrer">
              <div
                style={{
                  marginBottom: '2rem',
                  color: '#18AB47',
                  display: 'grid',
                  alignItems: 'center',
                  gridTemplateColumns: 'max-content auto',
                  columnGap: '8px',
                }}
              >
                <Iconify icon={myLocation16Filled} />
                <div
                  style={{
                    fontFamily: 'Nunito Sans',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                >
                  REQUEST RIDE
                </div>
              </div>
            </a>

            <UserMenu />
          </div>
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
