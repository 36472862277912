import { all, put, takeLatest } from 'redux-saga/effects'
import request from 'redux/network/actions'
import { notification } from 'antd'
import { actions } from './actions'
import API from '../api'

const { coordinatorResendInvite } = API

function* LOAD(payload) {
  yield put(request(coordinatorResendInvite(payload.payload.id), actions.LOAD))
}

function* SUCCESS(payload) {
  if (payload.payload.data.success) {
    yield notification.success({
      message: 'Coordinator invite status',
      description: payload.payload.data.message,
    })
  } else {
    yield notification.error({
      message: 'Coordinator invite status',
      description: payload.payload.data.message,
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD), takeLatest(actions.SUCCESS, SUCCESS)])
}
