import { get } from 'utils/net'
import APP_CONST from 'utils/constants'

const { url, GetToken, GetBaId } = APP_CONST

const apiUrl = `${url}/business-account`

const companyBilling = () => get(`${apiUrl}/${GetBaId()}/billing`, null, GetToken())

export default {
  companyBilling,
}
