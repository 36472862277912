import { ReactComponent as HealthIcon } from 'assets/healthIcon.svg'
import { ReactComponent as UsersIcon } from 'assets/usersIcon.svg'
import { ReactComponent as SettingsIcon } from 'assets/settingsIcon.svg'
import { ReactComponent as SupportIcon } from 'assets/supportIcon.svg'

export default async function getMenuData(subdomain, admin_type) {
  const conciergeMenu = [
    {
      title: 'Request',
      key: 'request',
      url: '/request',
    },
    {
      title: 'Active Rides',
      key: 'activity',
      url: '/activity',
    },
    {
      title: 'Past Rides',
      key: 'rides',
      url: '/rides',
    },
    {
      title: 'Flex Rides',
      key: 'flexRides',
      url: '/flex',
    },
  ]

  const businessMenu = [
    {
      title: admin_type === 'corporate_operator' ? 'HEALTH' : 'CONCIERGE',
      key: 'requestType',
      category: true,
      icon: admin_type === 'corporate_operator' ? 'custom' : 'fa fa-bell',
      iconComp: HealthIcon,
    },
    {
      title: 'Locations',
      key: 'locations',
      url: '/locations',
    },
    {
      title: 'Rides',
      key: 'rides',
      url: '/rides',
    },
    {
      title: 'Billing',
      key: 'billing',
      url: '/billing',
    },
    {
      divider: true,
    },
    {
      title: 'USERS',
      key: 'users',
      category: true,
      icon: 'custom',
      iconComp: UsersIcon,
    },
    {
      title: 'Coordinators',
      key: 'coordinators',
      url: '/coordinators',
    },
    {
      title: 'Employees',
      key: 'employees',
      url: '/employees',
    },
    {
      divider: true,
    },
    {
      title: 'SETTINGS',
      key: 'settings',
      url: '/settings',
      icon: 'custom',
      category: true,
      iconComp: SettingsIcon,
    },
    {
      title: 'SUPPORT',
      key: 'support',
      url: '/support',
      icon: 'custom',
      category: true,
      iconComp: SupportIcon,
    },
    // {
    //   divider: true,
    // },
    // {
    //   title: 'Request Ride',
    //   key: 'requestRide',
    //   url: admin_type === 'corporate_operator' ? 'https://health.waave.co' : 'https://concierge.waave.co',
    //   target: '_blank',
    //   icon: 'custom',
    //   iconComp: <Icon icon={myLocation16Filled} style={{ verticalAlign: 'middle' }} />,
    //   iconWrap: true,
    //   category: true,
    // },
  ]

  if (subdomain === 'business') {
    return businessMenu
  }

  return conciergeMenu
}
