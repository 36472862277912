/* eslint-disable react/jsx-indent */
import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useSelector } from 'react-redux'

import Layout from 'layouts'

const conciergeRoutes = [
  {
    path: '/request',
    Component: lazy(() => import('pages/concierge/request')),
    exact: true,
  },
  {
    path: '/activity',
    Component: lazy(() => import('pages/concierge/activity')),
    exact: true,
  },
  {
    path: '/rides',
    Component: lazy(() => import('pages/concierge/rides')),
    exact: true,
  },
  {
    path: '/flex',
    Component: lazy(() => import('pages/concierge/flexible')),
    exact: true,
  },
]

const businessRoutes = [
  {
    path: '/locations',
    Component: lazy(() => import('pages/business/locations')),
    exact: true,
  },
  {
    path: '/rides',
    Component: lazy(() => import('pages/business/rides')),
    exact: true,
  },
  {
    path: '/coordinators',
    Component: lazy(() => import('pages/business/coordinators')),
    exact: true,
  },
  {
    path: '/employees',
    Component: lazy(() => import('pages/business/employees')),
    exact: true,
  },
  {
    path: '/billing',
    Component: lazy(() => import('pages/business/billing')),
    exact: true,
  },
  {
    path: '/settings',
    Component: lazy(() => import('pages/business/settings')),
    exact: true,
  },
  {
    path: '/support',
    Component: lazy(() => import('pages/business/support')),
    exact: true,
  },
]

const defaultRoutes = [
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const Router = (props) => {
  const { history } = props
  const { routerAnimation, subdomain } = useSelector((state) => state.settings)
  let routes = [...defaultRoutes]
  let redirectPath = ''

  switch (subdomain) {
    case 'business':
      routes = [...businessRoutes, ...routes]
      redirectPath = '/locations'
      break
    default:
      routes = [...conciergeRoutes, ...routes]
      redirectPath = '/request'
      break
  }

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state

            return (
              <SwitchTransition>
                <CSSTransition key={location.pathname} appear classNames={routerAnimation} timeout={routerAnimation === 'none' ? 0 : 300}>
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to={redirectPath} />} />

                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}

                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default Router
