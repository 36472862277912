import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import WaaveHealth from 'assets/logo/waave_health_green_white.png'
import WaaveConcierge from 'assets/logo/waave_concierge_green_white.png'
import MenuPages from './MenuPages'
import MenuTop from './MenuTop'
// import Status from './Status'
// import LanguageSwitcher from './LanguageSwitcher'
// import Actions from './Actions'
import UserMenu from './UserMenu'
// import Cart from './Cart'
import style from './style.module.scss'

const mapStateToProps = ({ settings, user }) => ({ settings, user })

const MenuSimply = ({
  settings: { subdomain, menuColor, isMobileView },
  user: {
    company: { company_name },
  },
}) => {
  const pickLogo = (type) => {
    if (type === 'health') {
      return <img src={WaaveHealth} alt="logo" style={{ height: '18px' }} />
    }

    return <img src={WaaveConcierge} alt="logo" style={{ height: '18px', marginTop: '6px' }} />
  }

  return (
    <div
      className={classNames(style.menuSimply, {
        [style.menuSimply__white]: menuColor === 'white',
        [style.menuSimply__gray]: menuColor === 'gray',
      })}
      style={{ justifyContent: isMobileView && 'space-between' }}
    >
      <div className={`${style.menuSimply__logo}`}>
        {/* <div className={style.menuSimply__logo__letter}></div> */}
        {/* <div className={style.menuSimply__logo__name}>{logo}</div> */}

        {/* <img src="/resources/images/waave/logo.svg" alt="logo" /> */}
        {/* <div className={style.menuSimply__logo__descr}>{subdomain}</div> */}

        {/* <img src={WaaveHealth} alt="logo" style={{ height: '18px' }} /> */}

        {pickLogo(subdomain)}
      </div>

      <div>
        {isMobileView ? (
          <MenuPages />
        ) : (
          <div className="mr-auto">
            <MenuTop />
          </div>
        )}
      </div>

      {/* <div className="mr-3 d-xl-block d-none">
        <Status />
      </div>
      <div className="mr-3 d-none d-md-block">
        <Cart />
      </div>
      <div className="mr-3 d-none d-md-block">
        <Actions />
      </div>
      <div className="mr-3 d-none d-md-block">
        <LanguageSwitcher />
      </div> */}

      {!isMobileView && <div className={`${style.companyName} ml-auto mr-4`}>{company_name}</div>}

      <div className={!isMobileView && 'mr-1'}>
        <UserMenu />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(MenuSimply)
