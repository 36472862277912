import store from 'store'

function toQueryString(params, nesting = '') {
  return Object.entries(params)
    .filter(([k, v]) => k && v !== null)
    .map(([k, v]) => {
      const key = encodeURIComponent(k)

      // check if there are nested objects
      if (typeof v === 'object') {
        return toQueryString(v, nesting ? `${nesting}[${key}]` : `${key}`)
      }

      const value = encodeURIComponent(v)

      return nesting ? `${nesting}[${key}]=${value}` : `${key}=${value}`
    })
    .join('&')
}

export function api(options) {
  const { params, method = 'GET', token = '', url, headers } = options

  const lang = store.get('app.settings.locale') || 'en'

  const newHeaders = {
    Accept: 'application/json',
    // 'X-Auth-Token': JSON.parse(localStorage.getItem('user')).api_token,
    // 'X-Auth-Token': token,
    Authorization: `Bearer ${token}`,
    'Accept-Language': lang,
    ...headers,
  }

  const newOptions = {
    headers: { ...newHeaders },
    method,
    params,
    url,
  }

  let newUrl = url

  if (method === 'POST' || method === 'PUT') {
    newOptions.body = JSON.stringify(params)
  } else if (method === 'GET' && params) {
    // if params are sent as an object, try to serialize and then append to the provided url
    const qs = toQueryString(params)

    if (qs) {
      newUrl += url.indexOf('?') === -1 ? '?' : '&'
      newUrl += qs
    }
  }

  return fetch(newUrl, newOptions)
}

export function postJson(url, params, token, headers) {
  return {
    url,
    method: 'POST',
    params,
    token,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  }
}

export function putJson(url, params, token, headers) {
  return {
    url,
    method: 'PUT',
    params,
    token,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  }
}

export function get(url, params, token, headers) {
  return {
    url,
    method: 'GET',
    params,
    token,
    headers,
  }
}

export function destroy(url, params, token, headers) {
  return {
    url,
    method: 'DELETE',
    params,
    token,
    headers,
  }
}

export default {
  api,
  postJson,
  putJson,
  get,
  destroy,
}
