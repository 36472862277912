import flexiblePolyline from 'utils/flexiblePolyline'
import { actions } from './actions'

const { decode } = flexiblePolyline

const initialState = {
  routes: [], // HERE docs
  notice: [], // HERE docs for errors
  decodedPolylines: [],
  loading: false,
}

const Reducer = (state = initialState, action) => {
  const decodedPolys = []

  switch (action.type) {
    case actions.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.SUCCESS:
      // decode polylines
      if (!action.payload.data.notice) {
        const r = action.payload.data.routes

        r.forEach((el) => {
          el.sections.forEach((t) => {
            decodedPolys.push(decode(t.polyline))
          })
        })
      }

      return {
        ...state,
        loading: false,
        ...action.payload.data,
        decodedPolylines: decodedPolys,
      }
    case actions.RESET:
    case actions.ERROR:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default Reducer
