import { get, postJson, destroy } from 'utils/net'
import APP_CONST from 'utils/constants'

const { url, GetToken, GetBaId } = APP_CONST

const apiUrl = `${url}/business-account`

const list = (params) => get(`${apiUrl}/${GetBaId()}/employee?filters[ba_role]=0`, params, GetToken())
const create = (params) => postJson(`${apiUrl}/${GetBaId()}/employeeMassStore`, params, GetToken())
const remove = (id) => destroy(`${apiUrl}/${GetBaId()}/employee/${id}`, null, GetToken())
const update = (id, params) => postJson(`${apiUrl}/${GetBaId()}/employee/${id}`, params, GetToken())

export default {
  list,
  create,
  remove,
  update,
}
