import { networkActions } from 'redux/network/actions'

const actions = {
  ...networkActions('ridesFlexibleList'),
}

const list = () => {
  return {
    type: actions.LOAD,
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, list, reset }
