import { get } from 'utils/net'

const url =
  'https://router.hereapi.com/v8/routes?apiKey=v9uFC8pPf68qaWe_WulrsglbeoHbFkkLQUlRffpbblk&transportMode=car&units=imperial&return=summary,polyline'

const hereRouter = (params) => {
  let newUrl = ''
  newUrl += url

  // Form request format
  if (params.pickup.lat) {
    newUrl += `&origin=${params.pickup.lat},${params.pickup.lng}`

    params.stops.forEach((el, index, arr) => {
      if (index === arr.length - 1) {
        newUrl += `&destination=${el.lat},${el.lng}`
      } else {
        newUrl += `&via=${el.lat},${el.lng}`
      }
    })
  } else {
    // Rides list && History format
    newUrl += `&origin=${params.pickup[0]},${params.pickup[1]}`

    params.stops.forEach((el, index, arr) => {
      if (index === arr.length - 1) {
        newUrl += `&destination=${el[0]},${el[1]}`
      } else {
        newUrl += `&via=${el[0]},${el[1]}`
      }
    })
  }

  return get(newUrl)
}

export default hereRouter
