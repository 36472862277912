import { all, put, takeLatest } from 'redux-saga/effects'
import request from 'redux/network/actions'
import { cloneDeep } from 'lodash'
import { actions, reset } from './actions'
import calculateFare from './api'
import { actions as hereRouterActions } from '../hereRouter/actions'

function* LOAD(payload) {
  yield put(request(calculateFare(payload.payload), actions.LOAD))
}

function* LOADfromHereRouter(payload) {
  const shouldRun = payload.payload.trigger.extra.payload.calculateFare
  const hasRoutes = payload.payload.data.routes

  if (shouldRun && hasRoutes) {
    const newParams = cloneDeep(payload.payload.trigger.extra.payload)

    const stops = cloneDeep(newParams.stops)
    const dropoff = stops.pop()

    newParams.dropoff = dropoff

    const newStops = stops.map((el, index) => {
      const newEl = cloneDeep(el)
      newEl.address = newEl.name
      delete newEl.name
      newEl.index = index

      return newEl
    })

    newParams.stops = newStops

    yield put(request(calculateFare(newParams), actions.LOAD))
  }
}

function* RESETfromHereRouter() {
  yield put(reset())
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD, LOAD),
    takeLatest(hereRouterActions.SUCCESS, LOADfromHereRouter),
    takeLatest(hereRouterActions.RESET, RESETfromHereRouter),
  ])
}
