import { get, postJson, destroy } from 'utils/net'
import APP_CONST from 'utils/constants'

const { url, GetToken, GetBaId } = APP_CONST

const apiUrl = `${url}/business-account`

const locations = () => get(`${apiUrl}/${GetBaId()}/location`, null, GetToken())
const locationCreate = (params) => postJson(`${apiUrl}/${GetBaId()}/location`, params, GetToken())
const locationDelete = (id) => destroy(`${apiUrl}/${GetBaId()}/location/${id}`, null, GetToken())
const locationUpdate = (id, params) => postJson(`${apiUrl}/${GetBaId()}/location/${id}`, params, GetToken())

export default {
  locations,
  locationCreate,
  locationDelete,
  locationUpdate,
}
