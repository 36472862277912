import { postJson } from 'utils/net'
import APP_CONST from '../../utils/constants'

const { url, GetToken } = APP_CONST

const apiUrl = `${url}/dispatchRidesList`

const ridesList = (params) => postJson(apiUrl, params, GetToken())

export default ridesList
