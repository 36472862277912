import { postJson } from 'utils/net'
import APP_CONST from '../../utils/constants'

const { url, GetToken } = APP_CONST

const apiUrl = `${url}/updateRide`

const updateRide = (params) => postJson(apiUrl, params, GetToken())

export default updateRide
