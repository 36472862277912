import { all, put, takeLatest } from 'redux-saga/effects'
import request from 'redux/network/actions'
import { actions } from './actions'
import { actions as updateActions } from '../update/actions'
import API from '../api'

const { companySettings } = API

function* LOAD() {
  yield put(request(companySettings(), actions.LOAD))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD), takeLatest(updateActions.SUCCESS, LOAD)])
}
