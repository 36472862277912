import { networkActions } from 'redux/network/actions'

const actions = {
  ...networkActions('employee/create'),
}

const create = (payload) => {
  return {
    type: actions.LOAD,
    payload,
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, create, reset }
