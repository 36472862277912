import { actions } from './actions'

const initialState = {
  data: {},
  loading: false,
}

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload.data,
      }
    case actions.RESET:
    case actions.ERROR:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default Reducer
