import { get, postJson, putJson, destroy } from 'utils/net'
import APP_CONST from 'utils/constants'

const { url, GetToken } = APP_CONST

const apiUrl = `${url}/business/companyadmins`

const companyAdmins = () => get(apiUrl, null, GetToken())
const companyAdminCreate = (params) => postJson(apiUrl, params, GetToken())
const companyAdminUpdate = (id, params) => putJson(`${apiUrl}/${id}`, params, GetToken())
const companyAdminDelete = (id) => destroy(`${apiUrl}/${id}`, null, GetToken())
const companyAdminResendInvite = (id) =>
  postJson(`${apiUrl}/resendActivationEmail/${id}`, { id }, GetToken())

export default {
  companyAdmins,
  companyAdminCreate,
  companyAdminUpdate,
  companyAdminDelete,
  companyAdminResendInvite,
}
