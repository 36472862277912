import { networkActions } from 'redux/network/actions'

const actions = {
  ...networkActions('companyBilling'),
}

const load = () => {
  return {
    type: actions.LOAD,
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, load, reset }
