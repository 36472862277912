import store from 'store'

// Waave stuff

const bostonPolyPoints = [
  [42.736141, -70.727264],
  [42.452863, -70.856453],
  [42.383924, -70.922371],
  [42.339276, -70.911385],
  [42.327703, -70.883919],
  [42.278951, -70.757576],
  [42.226093, -70.708138],
  [42.138163, -70.664193],
  [42.085187, -70.620247],
  [42.056643, -70.581795],
  [42.08111, -70.438973],
  [42.125941, -70.246712],
  [42.133532, -70.190606],
  [42.117235, -70.025811],
  [42.047927, -69.976373],
  [41.966292, -69.943414],
  [41.86001, -69.910455],
  [41.753551, -69.888482],
  [41.702364, -69.899469],
  [41.620286, -69.937921],
  [41.550438, -69.965387],
  [41.476399, -69.97088],
  [41.385792, -69.97088],
  [41.303312, -69.937921],
  [41.266162, -69.948907],
  [41.212463, -69.998346],
  [41.216596, -70.108209],
  [41.237253, -70.229059],
  [41.286803, -70.333429],
  [41.319816, -70.437799],
  [41.323942, -70.553155],
  [41.319816, -70.668512],
  [41.290931, -70.73443],
  [41.282676, -70.794854],
  [41.307438, -70.893731],
  [41.365182, -70.976129],
  [41.4517, -71.091485],
  [41.443466, -71.162896],
  [41.431111, -71.305719],
  [41.406395, -71.399102],
  [41.373426, -71.46502],
  [41.344565, -71.525445],
  [41.328067, -71.569391],
  [41.323942, -71.668268],
  [41.315691, -71.778131],
  [41.299185, -71.838556],
  [41.282676, -71.909967],
  [41.253774, -71.964898],
  [41.237253, -72.030816],
  [41.237253, -72.113214],
  [41.290931, -72.190118],
  [41.282676, -72.256036],
  [41.262033, -72.360406],
  [41.241383, -72.464776],
  [41.237253, -72.57464],
  [41.228991, -72.67901],
  [41.228991, -72.739111],
  [41.166992, -73.063207],
  [41.146312, -73.090673],
  [41.125626, -73.156591],
  [41.113212, -73.211523],
  [40.984789, -73.645483],
  [41.104934, -73.722387],
  [41.220727, -73.497167],
  [41.319816, -73.546606],
  [42.050692, -73.491674],
  [42.236727, -73.454359],
  [42.743053, -73.273085],
  [43.209254, -73.256605],
  [43.210922, -71.696547],
  [43.226934, -71.257094],
  [43.760919, -70.8506],
  [43.974772, -70.542982],
  [43.760919, -69.971693],
  [43.522405, -70.147475],
  [43.346893, -70.389174],
  [43.130797, -70.553969],
  [43.063945, -70.623549],
  [43.015767, -70.711439],
  [42.911252, -70.766371],
  [42.838791, -70.777357],
  [42.806559, -70.766371],
  [42.779686, -70.747215],
  [42.765257, -70.750877],
  [42.757251, -70.750017],
  [42.743638, -70.741777],
  [42.734056, -70.717744],
  [42.736141, -70.727264],
]

const vehicleFareTypes = {
  Waave: {
    id: 1,
    name: 'Waave',
    description: ['3 seats', 'all vehicles', 'pickup in'],
  },
  Sedan: {
    id: 2,
    name: 'Sedan',
    description: ['3 seats', 'easy entry', 'pickup in'],
  },
  Max: {
    id: 3,
    name: 'Max',
    description: ['6 seats', 'more space', 'pickup in'],
  },
  Accessible: {
    id: 4,
    name: 'Accessible',
    description: ['3 seats', 'wheelchair accessible', 'pickup in'],
  },
  Green: {
    id: 5,
    name: 'Green',
    description: ['3 seats', 'electric or hybrid vehicle', 'pickup in'],
  },
  'eco,sedan': {
    // Filler ..not sure what IDs etc ....
    id: 6,
    name: 'Green Sedan',
    description: ['3 seats', 'electric or hybrid vehicle', 'pickup in'],
  },
}

const parseFare = (amount) => {
  const arr = amount.toString().split('.')

  if (!arr[1]) {
    return arr[0]
  }

  let result = ''
  const arr2 = arr[1].split('')

  result += arr[0]
  result += '.'
  result += arr2[0]
  result += arr2[1] ? arr2[1] : '0'

  return result
}

function formatPhoneNumber(phoneNumberString) {
  // eslint-disable-next-line prefer-template
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
}

// React env
const api = process.env.REACT_APP_API
const version = process.env.REACT_APP_API_VERSION
const url = api + version
const url_auth = process.env.REACT_APP_API_AUTH + version

const devToolsEnabled = process.env.REACT_APP_DEVTOOLS

const GetToken = () => {
  const token = store.get('app.token')
  return token
}

const GetBaId = () => {
  const baId = store.get('app.baId')
  return baId
}

const APP_CONST = {
  api,
  version,
  url,
  url_auth,
  GetToken,
  GetBaId,
  devToolsEnabled,
  // split
  bostonPolyPoints,
  vehicleFareTypes,
  formatPhoneNumber,
  parseFare,
}

export default APP_CONST
