import { networkActions } from 'redux/network/actions'

const actions = {
  ...networkActions('coordinator/update'),
}

const update = (id, params) => {
  return {
    type: actions.LOAD,
    payload: {
      id,
      params,
    },
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, update, reset }
