import { all, put, takeLatest } from 'redux-saga/effects'
import request from 'redux/network/actions'
import { actions } from './actions'
import { ridesFlexibleList } from '../api'

function* LOAD() {
  yield put(request(ridesFlexibleList(), actions.LOAD))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD)])
}
