import { get, postJson } from 'utils/net'
import APP_CONST from 'utils/constants'

const { url, GetToken, GetBaId } = APP_CONST

const apiUrl = `${url}/business-account/${GetBaId()}`

const companySettings = () => get(apiUrl, null, GetToken())
const companySettingsUpdate = (params) => postJson(apiUrl, params, GetToken())

export default {
  companySettings,
  companySettingsUpdate,
}
