import { postJson, get } from 'utils/net'
import APP_CONST from 'utils/constants'

const { url, GetToken } = APP_CONST

const apiUrl = `${url}/flexiblerides`

const dispatchRideFlexible = (params) => postJson(apiUrl, params, GetToken())
const ridesFlexibleList = () => get(apiUrl, null, GetToken())

export { dispatchRideFlexible, ridesFlexibleList }
