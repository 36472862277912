import { all, takeEvery, put, call, race, delay } from 'redux-saga/effects'
import { notification } from 'antd'
import _ from 'lodash'
import { api } from '../../utils/net'
import { actionNetwork } from './actions'
import { actions } from '../user/actions'

function showErrorNotification(content, title = 'Oops') {
  let description = 'Something went wrong, please retry.'

  if (_.isObject(content)) {
    const { errors, message } = content
    const keys = errors && Object.keys(errors)

    if (keys && keys.length > 0) {
      const {
        [keys[0]]: [firstError],
      } = errors
      description = firstError // show first error in the errors array
    } else if (message) {
      description = message
    }
  } else if (content) {
    description = content
  }

  notification.error({
    message: title,
    description,
  })
}

function* REQUEST(args) {
  const {
    payload: { options, action = 'missingAction', extra },
  } = args

  try {
    yield put({
      type: `${action}/request`,
      payload: {
        extra,
        action,
        options,
      },
    })

    const { response, timeout } = yield race({
      response: call(api, options),
      timeout: delay(60 * 1000),
    })

    // handle timeouts

    if (timeout) {
      yield put({
        type: `${action}/error`,
        payload: {
          error: {
            message: 'Connection timeout!',
          },
          trigger: {
            extra,
            action,
            options,
          },
        },
      })

      return
    }

    // Get json data
    const data = yield call([response, response.json])

    if (response.ok) {
      yield put({
        type: `${action}/success`,
        payload: {
          data: { ...data },
          trigger: {
            extra,
            action,
            options,
          },
        },
      })

      return
    }

    // response.ok = false
    yield put({
      type: `${action}/error`,
      payload: {
        error: { ...data },
        trigger: {
          extra,
          action,
          options,
        },
      },
    })

    // check status code
    switch (response.status) {
      case 401:
        console.log('401')
        showErrorNotification(data, '401')
        // user not authorized, redirect to login
        yield put({
          type: actions.LOGOUT,
        })
        break
      case 429:
        console.log('429')
        showErrorNotification('Too many attempts.', '429')
        break

      default:
        showErrorNotification(data)
        break
    }
  } catch (error) {
    const e = error

    yield put({
      type: `${action}/error`,
      payload: {
        error: { ...e },
        trigger: {
          extra,
          action,
          options,
        },
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionNetwork, REQUEST)])
}
