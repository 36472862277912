import { get, postJson, destroy } from 'utils/net'
import APP_CONST from 'utils/constants'

const { url, GetToken, GetBaId } = APP_CONST

const apiUrl = `${url}/business-account`

const coordinators = (params) => get(`${apiUrl}/${GetBaId()}/employee?filters[ba_role]=1`, params, GetToken())
const coordinatorCreate = (params) => postJson(`${apiUrl}/${GetBaId()}/employeeMassStore`, params, GetToken())
const coordinatorDelete = (id) => destroy(`${apiUrl}/${GetBaId()}/employee/${id}`, null, GetToken())
const coordinatorUpdate = (id, params) => postJson(`${apiUrl}/${GetBaId()}/employee/${id}`, params, GetToken())
const coordinatorResendInvite = (id) => postJson(`${apiUrl}/${GetBaId()}/employee/resendActivationEmail/${id}`, { id }, GetToken())

export default {
  coordinators,
  coordinatorCreate,
  coordinatorDelete,
  coordinatorUpdate,
  coordinatorResendInvite,
}
