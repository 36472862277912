import { all, put, takeLatest } from 'redux-saga/effects'
import request from 'redux/network/actions'
import { notification } from 'antd'
import { actions } from './actions'
import API from '../api'

const { update } = API

function* LOAD(payload) {
  yield put(request(update(payload.payload.id, payload.payload.params), actions.LOAD))
}

function* SUCCESS(payload) {
  if (payload.payload.data.business_account_employee) {
    yield notification.success({
      message: 'Employee updated successfully!',
    })
  } else {
    yield notification.error({
      message: 'Something went wrong!',
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD), takeLatest(actions.SUCCESS, SUCCESS)])
}
