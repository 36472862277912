import { networkActions } from 'redux/network/actions'

const actions = {
  ...networkActions('companySettings/update'),
}

const update = (params) => {
  return {
    type: actions.LOAD,
    payload: {
      ...params,
    },
  }
}

const reset = () => {
  return {
    type: actions.RESET,
  }
}

export { actions, update, reset }
